<template>
	<div class="container-fluid p-5">
		
		<h1>Uploader</h1>
		
		<div class="row mt-5">
			<div class="col-6">
				<form class="form">
					<div class="form-group">
						<label>Item ID</label>
						<input class="form-control" placeholder="Name" v-model="id">
						<button v-on:click="loadItem" type="submit" class="btn btn-primary">Load</button>
					</div>
					<div class="form-group">
						<label>Name</label>
						<input class="form-control" placeholder="Name" v-model="form.name">
					</div>
					<div class="form-group">
						<label>Description</label>
						<input class="form-control" placeholder="Description" v-model="form.description">
					</div>
				</form>
				
				
				<div class="col-12 text-center mt-3">
					<button v-on:click="submit" type="submit" class="btn btn-primary">Create</button>
					<button v-on:click="reset" type="submit" class="btn btn-primary">Reset</button>
				</div>
			</div>
			
			<div class="col-6">
				<div class="row">
					<div class="col-12">
						<GenericModelViewer @click="onModelClicked" v-if="item.sprite_uri !== ''" :poster_uri="item.poster_uri" :object_uri="item.sprite_uri"></GenericModelViewer>
					</div>
					
					<div class="col-12 mt-3">
						<form enctype="multipart/form-data">
							<input ref="file" type="file" class="file-upload" accept="image/*"/>
						</form>
					</div>
					
					
					<div class="col-12 text-center mt-3">
						<button :disabled="item.id === ''" v-on:click="uploadFile" type="submit" class="btn btn-primary">Upload Object</button>
					</div>
				</div>
			</div>
		
		</div>
		
		<div class="row mt-3">
			<div class="col-6">
				<div class="card">
					<h6>Form</h6>
					<pre><code class="text-white">{{ form }}</code></pre>
				</div>
			</div>
			
			<div class="col-6">
				<div class="card">
					<h6>Item</h6>
					<pre><code class="text-white">{{ item }}</code></pre>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Item from "@/api/item"
	import GenericModelViewer from "../../components/3d/GenericModelViewer";
	
	export default {
		name: "TestItemUploader",
		components: {GenericModelViewer},
		data() {
			return {
				logo: null,
				id: "",
				item: {
					id: -1,
					token_addr: '',
				}, //The item created
				form: {}
			}
		},
		methods: {
			submit: function (e) {
				e.preventDefault();
				let payload = Object.assign({}, this.form)
				this.send(payload);
			},
			
			reset: function (e) {
				e.preventDefault();
				this.item = {}
				this.id = '';
				this.form = this.resetItem()
			},
			
			send: function (payload) {
				payload.token_addr = this.uuidv4()
				
				if (this.form.sprite_uri === '')
					alert("Invalid sprite uri")
				
				if (this.form.poster_uri === '')
					alert("Invalid poster uri")
				
				console.log("Payload: ", payload);
				
				// axios.post("https://app.babilu.online/importer/requests", payload).then(resp => {
				Item.import(payload).then(resp => {
					console.log(resp.data);
					this.item = resp.data;
				})
			},
			
			onModelClicked: function (data) {
				console.log("clicked", this.item);
				
				if (this.item.sprite_uri.endsWith(".glb")) {
					data.then(res => this.uploadLogo(res))//Already blob
					return
				}
				
				fetch(data)
					.then(res => {
						res.blob().then(b => this.uploadLogo(b))
					})
			},
			
			uploadLogo: function (data) {
				const formData = new FormData();
				formData.append("wallet_addr", this.$store.state.wallet_addr)
				formData.append("file", data)
				
				return Item.updateLogo(this.item.token_addr, formData)
			},
			
			uploadFile: function () {
				const formData = new FormData();
				formData.append("file", this.$refs.file.files[0])
				formData.append("wallet_addr", this.$store.state.wallet_addr)
				
				return Item.updateFile(this.item.token_addr, formData).then(resp => this.item.sprite_uri = resp.data.file_uri)
			},
			
			uuidv4: function () {
				return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
					(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
				);
			},
			
			loadItem: function(e) {
				e.preventDefault()
				console.log("Loading item: ", this.id);
				Item.show(this.id).then(resp => this.item = resp.data)
			},
			
			resetItem: function () {
				return {
					owner_addr: this.$store.state.wallet_addr,
					chain_id: 0,
					contract_addr: "0x0",
					token_addr: "", //TODO uuid
					nft_platform: 0,
					metadata_uri: "",
					name: "",
					description: "",
					sprite_external: false,
					sprite_uri: "TEMP",
					poster_uri: "TEMP",
					item_type: 1,
					interaction_type: 1,
					display_type: 1, //3D
					details: {
						tile_x: 1,
						tile_y: 1,
						tile_z: 1,
						isometric_shader: false,
						scale_x: 1,
						scale_y: 1,
						scale_z: 1,
						position_x: 0,
						position_y: 0,
						position_z: 0
					},
				}
			}
		},
		mounted() {
			this.form = this.resetItem()
		}
	}
</script>

<style scoped>
	h1 {
		color: white;
		font-size: 2rem;
	}
	
	label {
		color: white;
	}
	
	
	.file-upload::-webkit-file-upload-button {
		visibility: hidden;
	}
	
	.file-upload::before {
		color: white;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		content: "Drag & Drop to Upload";
		white-space: break-spaces;
		outline: none;
		-webkit-user-select: none;
		cursor: pointer;
	}
	
	.file-upload {
		content: '';
		border: 1px solid #ccc;
		display: inline-block;
		cursor: pointer;
		height: 100px;
		padding: 5px;
		font-size: 0.7rem;
		text-align: center;
	}
</style>