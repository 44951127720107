<template>
	<div class="row">
		<h1>Remote Game Auth</h1>
		<p>Enter in your connection ID and authenticate with MetaMask or Phantom</p>
		
		
		<input class="form-control form-control-lg" v-model="game_uid">
		<button v-on:click="authUID" class="btn btn-primary btn-block">Authenticate</button>
	</div>
</template>

<script>
	export default {
		name: "RemoteGameAuth",
		data() {
			return {
				address: "0x0",
				game_uid: ""
			}
		},
		methods: {
			authUID: function (e) {
				e.preventDefault();
				
				console.log("Auth UID: " + this.game_uid);
				this.handleSignMessage(this.address, this.game_uid).then(resp => {
					console.log(resp);
					// this.game_uid_signed = resp.signature;
					this.onAuthRequestCallback(resp.publicAddress, resp.signature)
				})
				
			},
			handleSignMessage: function(publicAddress, nonce) {
				const str = `I am signing my game-authentication nonce: ${nonce}:${this.game_uid}`;
				const msg = this.web3.utils.fromUtf8(str);
				console.log(`${publicAddress} - ` + str);
				
				return new Promise((resolve, reject) =>
					this.web3.eth.personal.sign(msg, publicAddress,
						(err, signature) => {
							if (err) return reject(err);
							return resolve({publicAddress, signature});
						}
					)
				);
			},
			
			/**
			 * Called on success response from metamask signing
			 * Calls Babilu backend with the signed_payload and ve
			 *
			 * @param publicAddress |t_address
			 * @param signature containing the nonce & game_uid
			 */
			onAuthRequestCallback(publicAddress, signature) {
				var payload = {
					"public_address": publicAddress,
					"game_uid": this.game_uid,
					"signature": signature,
				};
				
				fetch(`${this.api_base_uri}/auth`, {
					body: JSON.stringify(payload),
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'POST'
				}).then(response => {
					return response.json();
				}).then(resp => {
					console.log("Auth Response: ", resp);
					this.game_uid_signed = signature;
				});
			},
		}
	}
</script>

<style scoped>

</style>